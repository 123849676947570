.home{
    height: 100vh;
}
.wrapper {
    max-width: 1200px;
    /* border: 1px solid red; */
    margin: 0 auto;
    /* height: auto !important; */
    height: 100%;
    padding: 60px 20px 60px 20px;
}
.title {
    /* margin-top: 50px; */
    font-size: 60px;
    font-weight: bolder;
    letter-spacing: 8px;
    color: white;
}
@media screen and (max-width: 768px) {
    .title {
        font-size: 30px;
    }
}
article {
    color: #7e7e7e;
    margin-left: 20px;
    margin-right: 20px;
    margin: 0 20px 20px 20px;
    line-height: 25px;
    /* margin-bottom: 30px; */
    
}

video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
}

.carousel-conatiner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel {
    /* border: 1px solid red; */
}
.carousel .slide {
    background-color: transparent !important;
}
.carousel.carousel-slider {
    height: 62vh !important;
    width: 50% !important;
    transform: translate(50%, 0%) !important;
}
@media screen and (max-width: 1024px) {
    .carousel.carousel-slider {
        height: 58vh !important;
        width: 100% !important;
        transform: translate(0%, 0%) !important;
    }
}
.close-modal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.close-modal-text {
    cursor: pointer;
    color: #DEAC27;
    margin-bottom: 10px;
}


.bgImage {
    height: 100%;
    min-width: 100%;
    z-index: -5;
}
.backgroundimg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -50;
}
#back1 {
    background: url("./assets/trade.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#back2 {
    background: url("./assets/about.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#back3 {
    background: url("./assets/proj3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#back4 {
    background: url("./assets/proj1.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#back5 {
    background: url("./assets/proj2.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes backgroundchangeFadeInOut {
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@-webkit-keyframes backgroundchangeFadeInOut {
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
#backgroundchange div:nth-of-type(1) {
  animation-delay: 25s;
  -webkit-animation-delay: 25s;
}
#backgroundchange div:nth-of-type(2) {
  animation-delay: 20s;
  -webkit-animation-delay: 20s;
}
#backgroundchange div:nth-of-type(3) {
  animation-delay: 15s;
  -webkit-animation-delay: 15s;
}
#backgroundchange div:nth-of-type(4) {
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
}
#backgroundchange div:nth-of-type(5) {
  animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
#backgroundchange div {
animation-name: backgroundchangeFadeInOut;
animation-timing-function: ease-in-out;
animation-iteration-count: infinite;
animation-duration: 25s;

-webkit-animation-name: backgroundchangeFadeInOut;
-webkit-animation-timing-function: ease-in-out;
-webkit-animation-iteration-count: infinite;
-webkit-animation-duration: 25s;
}

.home {
    background-color: rgba(39, 39, 39, 0.5);
}
.slogan {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 100px;
    font-weight: bolder;
    color: white;
}
@media screen and (max-width: 768px) {
    .slogan {
        font-size: 60px;
        line-height: 70px;
    }
}
.cta {
    margin-top: 80px;
    font-size: 16px;
    font-weight: 500;
}
.call {
    cursor: pointer;
}
.or {
    margin: 0 20px;
    color: #7e7e7e;
}
.yellow {
    color: #DEAC27;
}

.services {
    background-color: #272727;
    height: fit-content;
}

hr {
    width: 100px;
    height: 5px;
    background-color: #DEAC27;
    border: none;
    margin-top: 20px;
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
    hr {
        width: 80px;
    }
}
.services .grid-services {
    margin-top: 60px;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    /* padding-right: 20px; */
    /* height: 500px; */
}
@media screen and (max-width: 768px) {
    .services .grid-services {
        grid-template-columns: repeat(1, 1fr);
    }
}
.services .grid-services > div {
    /* border: 1px solid teal; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
    background-color: #2e2e2e;
}
.services .grid-services .subtitle {
    color: #DEAC27;
    /* color: white; */
    font-size: 30px;
    font-weight: 500;
    margin: 50px 0 20px 20px;
}
.wallpaper {
    width: 100%;
    height: 200px;
    /* border: 1px solid pink; */
    border-radius: 10px;
}
.wallpaper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
}
.manage {
  border: 1px solid #DEAC27;
  border-radius: 10px;
  background-color: transparent;
  color: #DEAC27;
  padding: 15px;
  cursor: pointer;
  transition: all 300ms;
  width: 50%;
  font-family: 'Roboto';
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 30px;
}
.manage:hover {
  background-color: #DEAC27;
  color: #272727;
  transition: all 300ms;
}


.history {
    background-color: #DEAC27;
    color: white;
    height: fit-content;
    /* height: 400px; */
}
.history .grid-history {
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
    grid-gap: 20px;
}
@media screen and (max-width: 768px) {
    .history .grid-history {
        grid-template-columns: repeat(1, 1fr);
    }
    .history .vr {
        display: flex;
        align-self: center;
        justify-self: center;
        border-bottom: 1px solid white;
        width: 20%;
        border-right: none !important;
    }
}
.history .grid-history > div {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.title-history {
    font-size: 60px;
    font-weight: bolder;
}
.subtitle-history {
    margin: 10px;
}
.history .vr {
    display: flex;
    align-self: center;
    border-right: 1px solid white;
    height: 70%;
}


.about {
    background-color: #272727;
    /* height: 50vh; */
}
.grid-about {
    margin: 0 auto;
    max-width: 1200px;
    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    /* height: 70vh; */
}
@media screen and (max-width: 768px) {
    .about .grid-about {
        grid-template-columns: repeat(1, 1fr);
    }
}
.article-about {
    margin: 20px 20px 20px 0;
}

.wallpaper-about {
    width: 100%;
    height: 100%;
    /* border: 1px solid pink; */
    /* border-radius: 10px; */
    padding-right: 20px;
    padding-bottom: 60px;
}
@media screen and (max-width: 1200px) {
    .wallpaper-about {
        padding-right: 0;
    }
}

.wallpaper-about img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
@media screen and (max-width: 768px) {
    .wallpaper-about img {
        border-radius: 0px !important;
    }
}
.about .subtitle {
    color: white;
    /* color: white; */
    font-size: 30px;
    font-weight: 500;
    /* margin: 50px 0 20px 20px; */
    /* margin-top: 30px; */
}
.about .content {
    margin-top: 50px;
}


.projects {
    background-color: #2E2E2E;
}
.grid-projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 60px;
    /* z-index: -100 !important; */
}
@media screen and (max-width: 768px) {
    .projects .grid-projects {
        grid-template-columns: repeat(1, 1fr);
    }
}
.grid-projects > div {
    border-radius: 10px;
}
.projects-wallpaper {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.projects-wallpaper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.cover, img {
  width: 100%;
  height: 100%;
  /* position: absolute; */
  top: 0;
  left: 0;
}
.cover {
    background-color: #DEAC27;
    border-radius: 10px;
    opacity: 0;
    transition: all 300ms;
    position: absolute;
    cursor: pointer;
    padding-left: 20px;
}
.cover-title {
    margin-top: 50px;
}
.projects-wallpaper img{
    transition: all 300ms;
}
.projects-wallpaper:hover img{
    transform: scale(1.2)
}
.projects-wallpaper:hover .cover{
    background-color: rgb(222, 173, 39);
    opacity: 1;
}
.projects .grid-projects .subtitle {
    color: white;
    opacity: 1;
    font-size: 30px;
    font-weight: 500;
    /* margin: 50px 0 20px 20px !important; */
    /* border: 1px solid red; */
}
.projects .grid-projects article {
    color: #272727;
    margin-top: 20px;
    margin-left: 0;
}


.contact {
    background-color: #272727;
    /* min-height: 80vh; */
}
.grid-contact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 60px;
}
@media screen and (max-width: 768px) {
    .contact .grid-contact {
        grid-template-columns: repeat(1, 1fr);
    }
}
.grid-contact > div {
    /* border: 1px solid mediumaquamarine; */
}
.contact-left-container {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */

}
.contact-text {
    display: flex;
    flex-direction: column;
    color: #DEAC27;
    font-size: 30px;
    margin-bottom: 30px;
}
.contact-each {
    /* border: 1px solid blueviolet; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
}
.contact-icon {
    /* border: 1px solid pink; */
    width: 50px;
}
.contact-details {
    /* border: 1px solid green; */
    margin-left: 30px;
}
.contact-main {
    color: white;
    font-size: 20px;
    margin: 5px 0;

}
.contact-sub {
    color: #7e7e7e;
    font-size: 16px;
    margin: 5px 0;
}
@media screen and (max-width: 375px) {
    .contact-sub {
        max-width: 220px;
    }
}

form {
    display: flex;
    flex-direction: column;
}

input, textarea {
    background-color: #272727;
    color: white;
    font-size: 16px;
}

input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-top: 15px;
    resize: vertical;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}
textarea {
    max-height: 100px;
    margin-bottom: 15px;
    font-family: 'Roboto';
}
.captcha {
    margin-bottom: 15px;
}
input:focus, textarea:focus, select:focus {
    outline-offset: 0px !important;
    outline: none !important;
    border: 1px solid #DEAC27 !important;
}

.contact button {
    border: 1px solid #DEAC27;
    border-radius: 10px;
    background-color: transparent;
    color: #DEAC27;
    padding: 15px;
    cursor: pointer;
    transition: all 300ms;
    width: 50%;
    font-family: 'Roboto';
    font-size: 16px;
}
.contact button:hover {
    background-color: #DEAC27;
    color: #272727;
    transition: all 300ms;
}

.footer {
    /* height: 200px; */
    background-color: #2E2E2E;
    color: #7e7e7e;
    font-size: 14px;
}
.grid-footer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
}
.footer .grid-footer > div {
    /* border: 1px solid green; */
}
.footer-left {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
}
.footer-left > span {
    padding: 3px 0;
}
.footer-left a {
    text-decoration: none;
    color: #7e7e7e;
    transition: all 300ms;
}
.footer-left a:hover {
    color: #DEAC27;
    transition: all 300ms;
}
.footer-right {
    display: flex;
    flex-direction: column;
}
.footer-right > span {
    padding: 3px 0;
}

.footer-right .velux {
  width: 150px;
}